import React from "react";
import {Accordion, Container, Col, Form, Row} from "react-bootstrap";

import { inverterList } from "../global";
import TableResults from "./TableResults";
import Count from "./Count";

import "./Result.css";


const Result = ({totalWatt = 0}) => {
  if (typeof totalWatt !== "number" || !totalWatt) {
    return null;
  }

  return (
    <Container>
      <Row className="g-1 App-result">
        <Col>
          <div className="results">
            <h4>Minimum Inverter Power (Watt):</h4>
            <div className="line-results">
              <Count key={totalWatt} count={totalWatt}/>
            </div>

          </div>
        </Col>

        <Form.Group>
          <div className="col-sm-12 App-why">
            <p className="note-result">
              <b>Note</b>:
              <br/>
              When possible, we can recommend amazon products base on your results.

            </p>

            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>12V Inverter(s) for {totalWatt}Watt</Accordion.Header>
                <Accordion.Body>
                    <TableResults
                      totalWatt={totalWatt}
                      results={inverterList[12]}
                    />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>24V Inverter(s)  for {totalWatt}Watt</Accordion.Header>
                <Accordion.Body>
                    <TableResults
                      totalWatt={totalWatt}
                      results={inverterList[24]}
                    />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

          </div>
        </Form.Group>

      </Row>
    </Container>
  )
};

export default Result;
