import {Accordion, Container} from "react-bootstrap";
import logo from "../images/logo2.png";

const TopContainer = () => (
  <Container className="App-why">
    <img src={logo} className="App-why-logo" alt="Solar Wire Size Calculator"/>
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Size your inverter base on your maximum power consumption!</Accordion.Header>
        <Accordion.Body className="why-body">
          Solar inverters are an important components of a solar panel system.
          They’re responsible for converting direct current (DC) electricity from your solar panels to alternating current (AC) electricity to power your appliances.
          <br />
          Inverters must be sized for the maximum peak load (when you use all your appliances at once) and your battery voltage.
          <br/>
          You can also share or save the result at the end nd see potential inverter products that would fit your need.
          <br/>
          <br/>
          <b>Step:</b>
          {"Providing the list of all appliances you are planning to connect. Inverters must be sized for the maximum peak load and the result will be a peak consumtion base on your list"}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  </Container>
);

export default TopContainer;
