const listAppliancesUnSort = [
  {
    "name": "Blender",
    "watt": "500"
  },
  {
    "name": "Bluray Player",
    "watt": "15"
  },
  {
    "name": "Band Saw – 14″",
    "watt": "1100"
  },
  {
    "name": "Can Opener",
    "watt": "150"
  },
  {
    "name": "Cable Box",
    "watt": "35"
  },
  {
    "name": "Belt Sander – 3″",
    "watt": "1000"
  },
  {
    "name": "Coffee Machine",
    "watt": "1000"
  },
  {
    "name": "DVD Player",
    "watt": "15"
  },
  {
    "name": "Chain Saw – 12″",
    "watt": "1100"
  },
  {
    "name": "Dishwasher",
    "watt": "1500"
  },
  {
    "name": "TV – LCD",
    "watt": "150"
  },
  {
    "name": "Circular Saw – 7-1/4″",
    "watt": "900"
  },
  {
    "name": "Espresso Machine",
    "watt": "800"
  },
  {
    "name": "TV – Plasma",
    "watt": "200"
  },
  {
    "name": "Circular Saw 8-1/4″",
    "watt": "1400"
  },
  {
    "name": "Freezer – Upright – 15 cu. ft.",
    "watt": "310"
  },
  {
    "name": "Satellite Dish",
    "watt": "25"
  },
  {
    "name": "Disc Sander – 9″",
    "watt": "1200"
  },
  {
    "name": "Freezer – Chest – 15 cu. ft.",
    "watt": "270"
  },
  {
    "name": "Stereo Receiver",
    "watt": "450"
  },
  {
    "name": "Drill – 1/4″",
    "watt": "250"
  },
  {
    "name": "Fridge – 20 cu. ft. (AC)",
    "watt": "350"
  },
  {
    "name": "Video Game Console",
    "watt": "150"
  },
  {
    "name": "Drill – 1/2″",
    "watt": "750"
  },
  {
    "name": "Fridge -16 cu. ft. (AC)",
    "watt": "300"
  },
  {
    "name": "Drill – 1″",
    "watt": "1000"
  },
  {
    "name": "Garbage Disposal",
    "watt": "450"
  },
  {
    "name": "CFL Bulb – 40 Watt Equivalent",
    "watt": "11"
  },
  {
    "name": "Hedge Trimmer",
    "watt": "450"
  },
  {
    "name": "Kettle – Electric",
    "watt": "1200"
  },
  {
    "name": "CFL Bulb – 60 Watt Equivalent",
    "watt": "18"
  },
  {
    "name": "Weed Eater",
    "watt": "500"
  },
  {
    "name": "Microwave",
    "watt": "1000"
  },
  {
    "name": "CFL Bulb – 75 Watt Equivalent",
    "watt": "20"
  },
  {
    "name": "Oven – Electric",
    "watt": "1200"
  },
  {
    "name": "CFL Bulb – 100 Watt Equivalent",
    "watt": "30"
  },
  {
    "name": "Clock Radio",
    "watt": "7"
  },
  {
    "name": "Toaster",
    "watt": "850"
  },
  {
    "name": "Compact Fluorescent 20 Watt",
    "watt": "22"
  },
  {
    "name": "Curling Iron",
    "watt": "150"
  },
  {
    "name": "Toaster Oven",
    "watt": "1200"
  },
  {
    "name": "Compact Fluorescent 25 Watt",
    "watt": "28"
  },
  {
    "name": "Dehumidifier",
    "watt": "280"
  },
  {
    "name": "Stand Mixer",
    "watt": "300"
  },
  {
    "name": "Halogen – 40 Watt",
    "watt": "40"
  },
  {
    "name": "Electric Shaver",
    "watt": "15"
  },
  {
    "name": "Incandescent 50 Watt",
    "watt": "50"
  },
  {
    "name": "Electric Blanket",
    "watt": "200"
  },
  {
    "name": "Box Fan",
    "watt": "200"
  },
  {
    "name": "Incandescent 100 Watt",
    "watt": "100"
  },
  {
    "name": "Hair Dryer",
    "watt": "1500"
  },
  {
    "name": "Ceiling Fan",
    "watt": "120"
  },
  {
    "name": "LED Bulb – 40 Watt Equivalent",
    "watt": "10"
  },
  {
    "name": "Humidifier",
    "watt": "200"
  },
  {
    "name": "Central Air Conditioner – 24,000 BTU NA",
    "watt": "3800"
  },
  {
    "name": "LED Bulb – 60 Watt Equivalent",
    "watt": "13"
  },
  {
    "name": "Radiotelephone – Receive",
    "watt": "5"
  },
  {
    "name": "Central Air Conditioner – 10,000 BTU NA",
    "watt": "3250"
  },
  {
    "name": "LED Bulb – 75 watt equivalent",
    "watt": "18"
  },
  {
    "name": "Radiotelephone – Transmit",
    "watt": "75"
  },
  {
    "name": "Furnace Fan Blower",
    "watt": "800"
  },
  {
    "name": "LED Bulb – 100 Watt Equivalent",
    "watt": "23"
  },
  {
    "name": "Sewing Machine",
    "watt": "100"
  },
  {
    "name": "Space Heater NA",
    "watt": "1500"
  },
  {
    "name": "Vacuum",
    "watt": "1000"
  },
  {
    "name": "Tankless Water Heater – Electric",
    "watt": "18000"
  },
  {
    "name": "Desktop Computer (Standard)",
    "watt": "200"
  },
  {
    "name": "Water Heater – Electric",
    "watt": "4500"
  },
  {
    "name": "Desktop Computer (Gaming)",
    "watt": "500"
  },
  {
    "name": "Window Air Conditioner 10,000 BTU NA",
    "watt": "900"
  },
  {
    "name": "Laptop",
    "watt": "100"
  },
  {
    "name": "Window Air Conditioner 12,000 BTU NA",
    "watt": "1060"
  },
  {
    "name": "LCD Monitor",
    "watt": "100"
  },
  {
    "name": "Well Pump – 1/3 1HP",
    "watt": "750"
  },
  {
    "name": "Modem",
    "watt": "7"
  },
  {
    "name": "Paper Shredder",
    "watt": "150"
  },
  {
    "name": "Clothes Dryer – Electric",
    "watt": "3000"
  },
  {
    "name": "Printer",
    "watt": "100"
  },
  {
    "name": "Clothes Dryer – Gas",
    "watt": "1800"
  },
  {
    "name": "Router",
    "watt": "7"
  },
  {
    "name": "Clothes Washer",
    "watt": "800"
  },
  {
    "name": "Smart Phone – Recharge",
    "watt": "6"
  },
  {
    "name": "Iron",
    "watt": "1200"
  },
  {
    "name": "Tablet – Recharge",
    "watt": "8"
  }
];

const sortAppliance = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

const listAppliances = listAppliancesUnSort.sort(sortAppliance);
export {
  listAppliances,
  sortAppliance
};
