import {listAppliances} from "../BatteryStep/listAppliances";

const convertUrlParam = (param = "") => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  if (params && params.a) {
    param = params.a;
  }

  try {
    const conv = atob(param);
    // ?a=MC0yJjQtMQ==
    // 0-2&2-2.3&4-1&10-0.5
    const appliances = [];

    if (conv.length) {
      conv.split("&").forEach((entry) => {
        const appliance = entry.split("-");
        appliances.push({
          name: listAppliances[Number(appliance[0])].name,
          id: Number(appliance[0]),
          idx: Number(appliance[0]),
          watt: listAppliances[Number(appliance[0])].watt,
          maxWatt: listAppliances[Number(appliance[0])].watt,
          count: Number(appliance[1])
        });
      });
    }
    return appliances;
  } catch (e) {
    console.warn("Wrong parameter passed", e);
    return null;
  }
};

const buildUrlParam = (appliances = []) => {
  let params = "";
  appliances.forEach((app, i) => {
    if (typeof app.idx === "number" && app.idx > -1) {
      params += app.idx;
      params += "-";
      params += app.count
      if (i < appliances.length-1) {
        params += "&";
      }
    } else if (typeof app.id === "number" && app.id > -1) {
      params += app.id;
      params += "-";
      params += app.count
      if (i < appliances.length-1) {
        params += "&";
      }
    }
  });

  const conv = btoa(params);
  const urlWithParams = new URL(window.location.origin);
  urlWithParams.searchParams.append("a", conv);
  return urlWithParams;
}

export {
  buildUrlParam,
  convertUrlParam,
};
