import {useEffect, useState} from "react";
import {
  Form as FormikForm,
  Field,
  FieldArray,
  Formik,
} from "formik";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {ImCross, GrAddCircle} from "react-icons/all";

import applianceIcon from "../images/appliance-icon.png";
import ModalInfo from "../ModalInfo";
import {listAppliances, sortAppliance} from "./listAppliances";
import {explanations} from "../global";

import "./BatteryStep.css";

const initialValuesState = {
  numberOfAppliances: 1,
  appliances: [],
  totalWattPerDay: 0,
};

const BatteryStep = ({onChangeAppliances = () => {}, appliances = [], setTotalWh = () => {}}) => {
  const [initialValues, setInitialValues] = useState(initialValuesState);

  useEffect(() => {
    if (appliances.length) {
      const newInitialValues = {...initialValuesState, appliances:[]};

      appliances.forEach((app) => {
        const idx = typeof app.id === "number" ? app.id : app.idx;
        newInitialValues.appliances.push({
          name: listAppliances[idx].name,
          watt: listAppliances[idx].watt,
          maxWatt: listAppliances[idx].watt * app.count,
          count: app.count,
          id: idx,
          idx: idx,
        });
        newInitialValues.numberOfAppliances += 1;
      });

      const totalWattPerDay = newInitialValues.appliances.reduce((acc, a) => {
        return Number(a.maxWatt) + acc;
      }, 0);
      newInitialValues.totalWattPerDay = totalWattPerDay;
      setInitialValues(newInitialValues);
      setTotalWh(totalWattPerDay);
    }
  }, [appliances, setTotalWh]);

  const handleSubmitForm = () => {};

  return (
    <Container className="App-Battery-container">
      <Formik
        onSubmit={handleSubmitForm}
        enableReinitialize={true}
        initialValues={initialValues}
      >
        {({
            handleSubmit,
            handleChange,
            values,
            setValues,
          }) => {
          const addAppliance = () => {
            const appliances = [...values?.appliances ?? []];
            appliances.push({name: '', watt: 0, maxWatt: 0, count: 1});
            setValues({...values, appliances});
          };

          const removeAppliance = (index) => {
            const appliances = [...values?.appliances ?? []];
            if (index > -1) {
              appliances.splice(index, 1);
            }
            const totalWattPerDay = appliances.reduce((acc, a) => {
              return Number(a.maxWatt) + acc;
            }, 0);

            setValues({...values, appliances, totalWattPerDay});
            onChangeAppliances(appliances);
            setTotalWh(totalWattPerDay);
          };

          return (
            <Form
              as={FormikForm}
              className="form-horizontal App-Battery-form"
              onSubmit={handleSubmit}
              role="form"
            >
              <Row>
                <Form.Group as={Col}>
                  <Form.Label style={{width: "auto"}}>List of appliances:</Form.Label>
                  <ModalInfo
                    title={explanations.daysbackup.title}
                    content={explanations.daysbackup.content}
                  />
                </Form.Group>
              </Row>

              <FieldArray name="appliances">
                {() => values.appliances.map((appliance, i) => {
                  const onSelectAppliance = (e) => {
                    const appliances = [...values?.appliances ?? []];
                    const idx = e.target.value;
                    if (idx > -1) {
                      const watt = listAppliances[idx].watt;

                      appliances[i].name = listAppliances[idx].name;
                      appliances[i].watt = Number(watt);
                      appliances[i].count = 1;
                      appliances[i].maxWatt = Number(watt);
                      appliances[i].idx = Number(idx);
                      appliances[i].id = Number(idx);
                      const totalWattPerDay = appliances.reduce((acc, a) => {
                        return Number(a.maxWatt) + acc;
                      }, 0);

                      setTotalWh(totalWattPerDay);
                      setValues({...values, appliances, totalWattPerDay});
                      onChangeAppliances(appliances);
                    }
                  };

                  const onChangeNumber = (e) => {
                    const appliances = [...values?.appliances ?? []];
                    let count = Number(e.target.value);

                    appliances[i].count = count;
                    appliances[i].maxWatt = Number(appliances[i].watt) * count;
                    const totalWattPerDay = appliances.reduce((acc, a) => {
                      return Number(a.maxWatt) + acc;
                    }, 0);
                    setTotalWh(totalWattPerDay);
                    setValues({...values, appliances, totalWattPerDay});
                    onChangeAppliances(appliances);
                  }

                  const originValue = listAppliances.findIndex((e) => {
                    return e.name === values.appliances[i].name
                  });

                  return (
                    <Row key={i} className="App-Battery-appliance">
                      <div
                        className="App-Battery-appliance-trash"
                        onClick={() => removeAppliance(i)}
                      >
                        <ImCross title="Remove"/>
                      </div>

                      <Row className="App-Battery-appliance-select">
                        <Col className="App-Battery-col">
                          <div className="App-Battery-appliance-electric">
                            <img alt="Appliance" width={25} src={applianceIcon} />
                          </div>
                          <Form.Select
                            aria-label="appliance"
                            value={originValue}
                            onChange={onSelectAppliance}
                            name="appliance"
                          >
                            <option value={-1}>Select an appliance</option>
                            {listAppliances
                              .sort(sortAppliance)
                              .map((option, idx) => (
                                <option key={idx} value={idx}>{option.name}</option>
                              ))}
                          </Form.Select>
                        </Col>
                        <Col className="App-Battery-col-count">
                          <label>Number</label>
                          <Field
                            disabled={originValue === -1}
                            name={`appliances.${i}.count`}
                            type="number"
                            className="form-control battery-count"
                            onChange={onChangeNumber}
                            value={values.appliances[i].count}
                          />
                        </Col>
                        <Col className="App-Battery-col-watt">
                          <label>Max Watt</label>
                          <Field
                            disabled
                            name={`appliances.${i}.watt`}
                            type="number"
                            className="form-control"
                            value={values.appliances[i].maxWatt}
                          />
                        </Col>
                      </Row>
                    </Row>
                  );
                })}
              </FieldArray>

              <Row className="App-Battery-appliance-total">
                <Button
                  onClick={addAppliance}
                  variant="warning"
                  style={{width: "100%"}}
                >
                  <GrAddCircle/>{" ADD APPLIANCE"}
                </Button>
              </Row>
            </Form>
          )
        }}

      </Formik>
    </Container>
  )
};

export default BatteryStep;
