const explanations = {
  wh: {
    title: "Power Consumption per day (Watt hour)",
    content: "A Watt Hour is a unit of measurement for power over a period of time (an hour), " +
      "or in our case, a way of measuring capacity. One Watt hour is equal to one Watt of " +
      "average power flow over an hour. " +
      "One Watt over four hours would be four Watt Hours of power. If we only use  LED of 10W for 5hours a day, our final Wh would be 10x5 = 50Wh",
    video: "cxkVxi9P0EA",
  },
  daysbackup: {
    title: "Number of day of backup",
    content: "Decide how many days of autonomy you want your solar system to be able to provide. The longer, the bigger you battery bank will be.",
  },
  mdd: {
    title: "Battery type",
    content: "Lead-acid batteries cost less up front, but they have a shorter lifespan and should only be run to 50% depth of discharge. Lithium batteries are much more expensive, but they have a longer lifespan and can handle deep discharges of 100%.",
    video: "SID-f7XgpYk",
  },
  voltage: {
    title: "Voltage",
    content: "Select which voltage you are planning to support. Usually, 12V, 24V or 48V are the most used.",
  }
};



const inverterList = {
  // Voltage battery 12V
  12: {
    300: {
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B082PN7C6J&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://amzn.to/3EEPhUd",
      name: "BESTEK 300Watt Pure Sine Wave Power Inverter DC 12V to AC 110V",
      desc: "300Watt Pure Sine Wave Power Inverter DC 12V to AC 110V Car Plug Inverter Adapter Power Converter with 4.2A Dual USB Charging Ports and 2 AC Outlets Car Charger"
    },
    500: {
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B082MDRC64&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://amzn.to/3lSV8gb",
      name: "BESTEK 500W Pure Sine Wave Power Inverter DC 12V to AC 110V",
      desc: "500W Pure Sine Wave Power Inverter DC 12V to AC 110V Car Plug Inverter Adapter Power Converter with 4.2A Dual USB Charging Ports and 2 AC Outlets Car Charger",
    },
    1000: {
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07XYR1BS3&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://amzn.to/3lQx4dD",
      name: "BESTEK 1000W Pure Sine Wave Power Inverter DC 12V to 110V AC",
      desc:"1000W Pure Sine Wave Power Inverter DC 12V to 110V AC Car Inverter with Digital LCD Display 4.2A Dual USB Charging Ports",
    },
    1500: {
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B01COA0UTE&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://amzn.to/3hXB8rn",
      name: "GoWISE Power 1500W Pure Sine Wave Inverter",
      desc: "1500W Power Inverter 12V DC to 120 V AC with 3 AC Outlets, 1 5V USB Port, 2 Battery Cables, and Remote Switch (3000W Peak) PS1005"
    },
    2000: {
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0131NFWX2&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://amzn.to/3u2xbXf",
      name: "GoWISE Power 2000W Pure Sine Wave Power Inverter",
      desc: "2000W Power Inverter 12V DC to 120V AC with 3 AC Outlets + 1 5V USB Port, Remote Switch and 2 Battery Cables (4000W Peak) PS1003"
    },
    3000: {
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0131PZ9J2&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://amzn.to/3kwsFNL",
      name: "GoWISE Power 3000W Pure Sine Wave Power Inverter",
      desc: "GoWISE Power PS1004 3000W Continuous 6000W Surge Peak Power Pure Sine Wave Inverter with Starter Cables and 4 Output Sockets"
    },
    5000: {
      img : "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07T8F1DP1&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://amzn.to/3nUnQjm",
      name: "Giandel Pure Sine Wave Power Inverter 5000W DC 12V to AC 110V 120V",
      desc: "Giandel 5000Watt Heavy Duty Modified Sine Wave Power Inverter DC 12volt to AC 120volt with LCD Display 4 AC Sockets Dual USB Ports & Remote Control"
    }
  },
  24: {
    800: {
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B078MQL27R&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://amzn.to/39r6h23",
      name: "WZRELB Backup Power Off Grid Pure Sine Wave Inverter 800W 24Vdc",
      desc: "Pure Sine Wave Inverter 800W 24Vdc to 120Vac 60Hz USA Outlets Power Converter"
    },
    1200: {
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07PQTMD6P&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://amzn.to/3u0Ckiw",
      name: "Giandel Pure Sine Wave Power Inverter 1200W DC 24V to AC 110V 120V",
      desc: "24V Pure Sine Wave Power Inverter 1200W DC 24V to AC 120V with Remote Controller and LED Display 2.4A USB Port Dual AC Outlets"
    },
    2000: {
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07GDH711G&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://amzn.to/3EHICbL",
      name: "Giandel Pure Sine Wave Power Inverter 2000W DC 24V to AC 110V 120V",
      desc: "Giandel 24 Volt 2000W Pure Sine Wave Power Inverter DC 24V to AC120V with Dual AC Outlets with Remote Control 2.4A USB and LED Display"
    },
    3000: {
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07JQKQQTP&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://amzn.to/3hXSynU",
      name: "Giandel Pure Sine Wave Power Inverter 3000W DC 24V to AC 110V 120V",
      desc: "24V Pure Sine Wave Power Inverter 3000Watt DC 24V to AC120V with Dual AC Outlets with Remote Control 2.4A USB and LED Display"
    },
    3500: {
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07LG9Q454&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://amzn.to/3AzyZcW",
      name: "WZRELB RBP350024VCRT 3500W 24V 120V Pure Sine",
      desc: "3500W 24V 120V Pure Sine Wave Solar Power Inverter with Remote Control Switch"
    },
    5000: {
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B088GXQ428&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://amzn.to/2ZdlnWO",
      name: "WZRELB Inverter Pure Sine Wave 5000W - 24V",
      desc: "Pure Sine Wave 5000W (10000W Surge) 24V Power Inverter DC to AC Power "
    }
  }
};

export {
  explanations,
  inverterList
};
