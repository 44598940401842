import React from "react";
import {Button} from "react-bootstrap";

const TableResults = ({results, totalWatt}) => {
  let invtMax = 0;
  const res = Object.keys(results);

  return (
    <table className="result-inverter-table">
      <tbody>
      {
        res.map((invKey, i) => {
          if (invKey < totalWatt || invtMax > 1) {
            return null;
          }
          invtMax++;
          return (
            <tr key={i}>
              <td>{`${results[invKey].name}`}</td>
              <td>
                <div className="result-amazon-link">
                  <img alt={`Amazon battery ${results[invKey].name}`} src={results[invKey].img} />
                  <a href={results[invKey].link} target="_blank" rel="noreferrer">
                    <Button variant="warning">
                      Buy on Amazon
                    </Button>
                  </a>
                </div>
              </td>
            </tr>
          )
        })
      }
      </tbody>
    </table>
  )
};

export default TableResults;
