import React, {useEffect, useState} from "react";

import Disclaimer from "./Disclaimer";

import "./App.css";

import BatteryStep from "./BatteryStep";
import Result from "./Result";
import TopContainer from "./TopContainer";
import ShareLinkStep from "./ShareLinkStep";
import {convertUrlParam} from "./tools/convert";

function App() {
  const [currentAppliances, setCurrentAppliances] = useState([]);
  const [newParams, setNewParams] = useState(Date.now());
  const [totalWatt, setTotalWh] = useState(0);

  useEffect(() => {
    // Check for parameters passed to the URL to prepopulate appliances
    const appliances = convertUrlParam();

    setCurrentAppliances(appliances);
    setNewParams(Date.now());
  }, []);

  const handleOnChangeAppliances = (values) => {
    setCurrentAppliances(values);
  };

  return (
    <div className="App">
      <TopContainer />

      <BatteryStep
        key={newParams}
        appliances={currentAppliances}
        onChangeAppliances={handleOnChangeAppliances}
        setTotalWh={setTotalWh}
      />

      <Result totalWatt={totalWatt} />

      <ShareLinkStep currentAppliances={currentAppliances} />

      <Disclaimer />
    </div>
  );
}

export default App;
