import React, {useEffect, useState} from "react";
import FlipNumbers from "react-flip-numbers";

import "./Count.css";

const Count = ({count = 0}) => {
  const [countNbr, setCountNbr] = useState(["0","0","0","0"]);

  useEffect(() => {
    if (count) {
      let nbr = String(count);
      if (count < 1000) {
        nbr = "0" + nbr;
      }

      setCountNbr(nbr.split(""));
    }
  }, [count]);

  return (
    <div
      className="counter"
      key={count}
      style={{
        width: (countNbr.length * 60) + "px",
      }}
    >
      <FlipNumbers
        play
        color="#0a0a0a"
        background="#fff"
        width={40}
        height={50}
        numbers={countNbr}
      />
    </div>
  )
}

export default Count;
